<template>
  <main class="supplier-payment supplier-payment-999 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name pt10">
          <span>{{ $t("SUPPLIER_PAYMENT.LOAD_MULTILINE_CARD") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/card.png"
          />
        </div>

        <div class="payment-caption-wrapper d-flex justify-content-center pt10">
        <router-link
          :to="{
            name: 'Dashboard'}" 
            tag="a"
            exact
        >
          <a class="primary-btn bg-2 cancel-btn pointer">
            {{ $t("SUPPLIER_PAYMENT.CANCELATION") }}
          </a>
          </router-link>
        </div>
      </div>
    </section>

    <section class="payment-form-section m-t-50">
      <div class="text-center fs28 color-red3 weight-700">
        {{ $t("SUPPLIER_PAYMENT.MULTILINE_CARD_TXT1") }}
      </div>
      <div class="d-flex justify-content-center pt10 m-t-30">
      <router-link
        :to="{
          name: 'SupplierPaymentResult',
          params: {supplier_id: '999' }}" 
          tag="a"
          exact
        >
        <a class="primary-btn pointer" @click="handleRavkav">
          {{ $t("SUPPLIER_PAYMENT.CLICK_VIEW_CHARGE_CARDS") }}
        </a>
         </router-link>
      </div>
      
      <div class="text-center fs28 color-red3 weight-700 m-t-20">
      
        {{ $t("SUPPLIER_PAYMENT.POPUP_MUST_CONFIRMED") }}
      </div>
      <div class="text-center fs23 weight-700 m-t-50">
        {{ $t("SUPPLIER_PAYMENT.MULTILINE_CARD_TXT2") }}
      </div>
      <div class="os-part"> 
        <button @click="goToSiteWindows()" class="each-os">
          <img src="/assets/img/os/windows.png" alt="">
          <p class="fs23">{{ $t("SUPPLIER_PAYMENT.WINDOWS") }}</p>
        </button>
        <button @click="goToSiteUbuntu()" class="each-os">
          <img src="/assets/img/os/ubuntu.png" alt="">
          <p class="fs23">{{ $t("SUPPLIER_PAYMENT.UBUNTU") }}</p>
        </button>
        <button @click="goToSiteMac()" class="each-os">
          <img src="/assets/img/os/apple.png" alt="">
          <p class="fs23">{{ $t("SUPPLIER_PAYMENT.MACINTOSH") }}</p>
        </button>
        <a 
          class="each-os link" 
          href="/assets/download/config.exe"
          download
        >
          <img src="/assets/img/icons/setting.png" alt="">
          <p class="fs23">{{ $t("COMMON.CONFIG") }}</p>
        </a>
        <a 
          class="each-os link" 
          href="/assets/download/ravkavcrt.exe"
          download
        >
          <img src="/assets/img/icons/NicePng_download-symbol-png_3655005.png" alt="">
          <p class="fs23">{{ $t("COMMON.CERTIFICATE") }}</p>
        </a>
      </div>
    </section>
  <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  components: {
    Loading
  },
  name: "SupplierPayment999",
  props: {
    msg: String,
  },
  data: () => {
    return {
    };
  },
  computed: {
    ...mapState({
        isConfirm: (state) => state.chargeRavkav_module.isConfirm,
        noCard: (state) => state.chargeRavkav_module.noCard,
        loading: (state) => state.chargeRavkav_module.loading,
    }),
  },
  methods: {
    ...mapActions('chargeRavkav_module', {
        connectReader:'connectReader',
        charge:'charge',
        backToRootScreen:'backToRootScreen',
        closeNoCard:'closeNoCard'
      }),
    handleRavkav() {
      this.connectReader()
    },
    handleCharge() {
      this.charge()
    },
    rootScreen(change){
      this.backToRootScreen(change)
    },
    goToSiteWindows() {
      window.open("https://ravkavonline.s3.amazonaws.com/win/ravkav-online-latest.exe")
    },
    goToSiteUbuntu() {
      window.open("https://ravkavonline.s3.amazonaws.com/linux/ravkav-online-latest.deb")
    },
    goToSiteMac() {
      window.open("https://ravkavonline.s3.amazonaws.com/osx/ravkav-online-latest.pkg")
    }
  }
};
</script>
<style lang="scss" scoped>
  .primary-btn {
    width: 500px;
  }
  .supplier-payment-999 {
    .supplier-logo {
      width: 120px;
    }
    .payment-product-name,
    .payment-form-section-bottom-block-text {
        font-size: 30px;
    }
    .cancel-btn {
      width: 160px;
    }
  }
  .ltr-type {
    .payment-form {
        text-align: left; 
    }
    .input-tooltip-container {
        left: unset;
        right: 0;
    }
    .payment-form-with-tooltips .payment_field_wrapper .input {
        margin-left: 0;
        margin-right: 12px;
    }
  }
  .os-part {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .each-os {
      display: flex;
      align-items: center;
      margin: 0 30px;
      img {
        margin: 0 20px;
        width: 50px;
      }
      p {
        margin: 0;
      }

    }
  }
</style>