<template>
  <main class="supplier-payment supplier-payment-186 page_bg_grey">
    <section class="payment-caption-section">
      <div class="payment-caption-container">
        <div class="payment-caption-wrapper payment-product-name">
          <span>{{ $t("SUPPLIER_PAYMENT.PURCHASE_POWER_CODE_TXT") }}</span>
        </div>

        <div class="payment-caption-wrapper text-center">
          <img
            class="supplier-logo"
            src="/assets/img/logo/n-logo.png"
          />
        </div>

        <div class="payment-caption-wrapper">&nbsp;</div>
      </div>
    </section>

    <section class="payment-form-section">
      <form class="payment-form payment-187">
        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("REPORT_SALES.CONTRACT_NUMBER") }}</label>
          <input
            id="contractNumber"
            class="input"
            type="tel"
            v-model="contractNumber"
            v-model.lazy="$v.contractNumber.$model"
            :class="{ 'validation-error': $v.contractNumber.$error }"
          />
        </div>

        <div v-show="false" class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("CARMEL_TUNNELS.INVOICE_SUM") }}</label>
          <input
            id="sumToPay"
            class="input w-100"
            type="number"
            step="0.01"
            v-model="sumToPay"
            v-model.lazy="$v.sumToPay.$model"
            :class="{ 'validation-error': $v.sumToPay.$error }"
          />
        </div>

        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("CARMEL_TUNNELS.PAYMENT_SUM") }}</label>
          <input
            id="price"
            class="input w-100"
            type="number"
            step="1"
            min="0"
            v-model="price"
            v-model.lazy="$v.price.$model"
            :class="{ 'validation-error': $v.price.$error }"
          />
          <p class="color-red2">
            {{ $t("SUPPLIER_PAYMENT.COMMISSION_DES_TXT") }}
          </p>
        </div>

        <div class="payment_field_wrapper">
          <label class="label payment_field_label" for>{{ $t("CARMEL_TUNNELS.MOBILE_PHONE_NUMBER") }}</label>
          <input
            id="phoneNumber"
            class="input"
            type="tel"
            v-model="phoneNumber"
            v-model.lazy="$v.phoneNumber.$model"
            :class="{ 'validation-error': $v.phoneNumber.$error }"
          />
        </div>

        <a class="link payment-button" @click="handleSupplierPaymentSubmit">{{ $t("CARMEL_TUNNELS.WHOLE") }}</a>
      </form>
      <div class="payment-form-section-bottom-block">
        <span class="payment-form-section-bottom-block-text color-red2">{{ $t("SUPPLIER_PAYMENT.PAY_ATTENTION_TXT") }}</span>
      </div>
      <div class="payment-form-section-bottom-block m-t-20">
        <span class="payment-form-section-bottom-block-text">{{ $t("CARMEL_TUNNELS.RESULT_TXT") }}</span>
      </div>
      
    </section>
  </main>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import appConfig from "../../../appConfig";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  integer
} from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  name: "SupplierPayment186",
  props: {
    msg: String,
  },
  data: () => {
    return {
      contractNumber: "",
      price: "",
      phoneNumber: "",
      sumToPay: "",
      error: 0
    };
  },
  validations() {
    return {
      contractNumber: {
        required
      },
      sumToPay: {
        // required,
        // minValue: minValue(0),
      },
      price: {
        required,
        integer,
        maxPaymentSum: this.maxPaymentSumValidation(this.maxPaymentSum),
      },
      phoneNumber: {
        required,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      error: {
        maxValue: maxValue(0)
      }
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      supplier_items: (state) => state.supplier.supplier_items,
      suppliers: (state) => state.supplier.suppliers,
      suppliers_products: (state) => state.supplier.suppliers_products,
      current_payment_data: (state) =>
        state.supplier_payment.current_payment_data,
    }),
    supplierId() {
      return this.$route.params.supplier_id;
    },
    supplierData() {
      let supplierDataFromSuppliersItemsData = null;
      if (this.supplier_items) {
        for (let supplier_type in this.supplier_items) {
          for (let suppliers_type_item of this.supplier_items[supplier_type]) {
            if (suppliers_type_item.id == this.supplierId) {
              supplierDataFromSuppliersItemsData = { ...suppliers_type_item };
              break;
            }
          }
        }
      }

      let supplierDataFromSuppliersData = null;
      if (this.suppliers && this.supplierId in this.suppliers) {
        supplierDataFromSuppliersData = { ...this.suppliers[this.supplierId] };
      }

      let supplierData = supplierDataFromSuppliersData
        ? supplierDataFromSuppliersData
        : supplierDataFromSuppliersItemsData;
      return supplierData ? supplierData : null;
    },
    supplierProductsAll() {
      let supplierProducts = [];
      if (
        this.suppliers_products &&
        this.supplierId in this.suppliers_products
      ) {
        let supplerProductsByType = this.suppliers_products[this.supplierId];

        if (supplerProductsByType) {
          for (let supplier_product_type in supplerProductsByType) {
            for (let supplier_product of supplerProductsByType[
              supplier_product_type
            ]) {
              supplierProducts.push(supplier_product);
            }
          }
        }
      }

      return supplierProducts ? supplierProducts : [];
    },
    productId() {
      let productId = null;

      if (this.productData && "id" in this.productData && this.productData.id) {
        productId = this.productData.id;
      }

      return productId ? productId : null;
    },
    productData() {
      let productData = null;

      if (
        this.supplierProductsAll &&
        this.supplierProductsAll.length &&
        "0" in this.supplierProductsAll &&
        this.supplierProductsAll[0]
      ) {
        productData = this.supplierProductsAll[0];
      }

      return productData ? productData : null;
    },
    finalCommission() {
      let finalCommission = 0;

      if (
        this.productData &&
        "final_commission" in this.productData &&
        this.productData.final_commission
      ) {
        finalCommission = this.productData.final_commission;
      }

      return finalCommission ? finalCommission : 0;
    },
    maxPaymentSum() {
      let maxPayment = 0;

      if (
        this.productData &&
        "max_payment" in this.productData &&
        this.productData.max_payment
      ) {
        maxPayment = this.productData.max_payment;
      }

      return maxPayment ? maxPayment : 0;
    },
  },
  watch: {
    // sumToPay(sumToPay) {
    //   if (!this.isNumber(sumToPay) || !parseFloat(sumToPay)) {
    //     sumToPay = "";
    //   }
    //   sumToPay = parseFloat(sumToPay);
    //   this.price =
    //     sumToPay +
    //     (this.finalCommission &&
    //     this.isNumber(this.finalCommission) &&
    //     this.finalCommission > 0 &&
    //     parseFloat(this.finalCommission)
    //       ? parseFloat(this.finalCommission)
    //       : 0);
    // },
  },
  created() {
    this.setPaymentData();
  },
  methods: {
    ...mapMutations("supplier_payment", {
      setCurrentPaymentData: "setCurrentPaymentData",
    }),
    ...mapActions('supplier_payment', {
      getSupplierPaymentDetails: 'getSupplierPaymentDetails'
    }),
    getApiURL() {
      return appConfig.apiUrl;
    },
    async handleSupplierPaymentSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const supplierId = this.supplierId;
      const itemId = this.productId;
      const contractNumber = this.contractNumber;
      let price = this.price;
      const phoneNumber = this.phoneNumber;
      const sumToPay = this.sumToPay;
      const finalCommission = this.finalCommission;

      if (supplierId != '' && itemId != '' && contractNumber != ''
        && phoneNumber != '') {
        await this.getSupplierPaymentDetails(
            {
                supplier_id: supplierId,
                payment_data: {
                    supplierId: supplierId,
                    itemId: itemId,
                    billNum: contractNumber,
                    billNumber: contractNumber,
                    price: price,
                    phoneNumber: phoneNumber
                }
            }
        )
          .then(
            result_data => {
                if (result_data && result_data.hasOwnProperty('success') && result_data.success
                    && result_data.success.hasOwnProperty('price')) {
                    this.price = result_data.success.price

                    if (result_data.success.hasOwnProperty('address')) {
                        this.address = result_data.success.address
                    }

                    if (result_data.success.hasOwnProperty('invoice')) {
                        this.invoice = result_data.success.invoice
                    }

                    if (result_data.success.hasOwnProperty('name')) {
                        this.name = result_data.success.name
                    }

                    if (result_data.success.hasOwnProperty('last_sale')) {
                        this.last_sale = result_data.success.last_sale
                    }

                    if (result_data.success.hasOwnProperty('counter_number')) {
                        this.counter_number = result_data.success.counter_number
                    }

                    if (result_data.success.hasOwnProperty('real_amount')) {
                        this.real_amount = result_data.success.real_amount
                    }

                    if (result_data.success.hasOwnProperty('net_amount')) {
                        this.net_amount = result_data.success.net_amount
                    }

                    if (result_data.success.hasOwnProperty('ktwh')) {
                        this.ktwh = result_data.success.ktwh
                    }

                    if (result_data.success.hasOwnProperty('amount_of_debts')) {
                        this.amount_of_debts = result_data.success.amount_of_debts
                    }

                    if (result_data.success.hasOwnProperty('total_charges_or_additional_credits')) {
                        this.total_charges_or_additional_credits = result_data.success.total_charges_or_additional_credits
                    }
                } else {
                    this.error = 1;
                }

            },
            error => {
                this.error = 1
            }
          );
      }

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error = 0;
        return;
      }

      price = this.price
      const address = this.address
      const invoice = this.invoice
      const name = this.name
      const last_sale = this.last_sale
      const counter_number = this.counter_number
      const real_amount = this.real_amount
      const net_amount = this.net_amount
      const ktwh = this.ktwh
      const amount_of_debts = this.amount_of_debts
      const total_charges_or_additional_credits = this.total_charges_or_additional_credits

      if (supplierId != '' && itemId != '' && contractNumber != ''
        && price != '' && phoneNumber != '') {
        this.setCurrentPaymentData(
          {
            supplier_id: supplierId,
            payment_data: {
              supplierId: supplierId,
              itemId: itemId,
              contractNumber: contractNumber,
              price: price,
              sumToPay: sumToPay,
              phoneNumber: phoneNumber,
              address: address,
              invoice: invoice,
              name: name,
              finalCommission: finalCommission,
              last_sale: last_sale,
              counter_number: counter_number,
              real_amount: real_amount,
              net_amount: net_amount,
              ktwh: ktwh,
              amount_of_debts: amount_of_debts,
              total_charges_or_additional_credits: total_charges_or_additional_credits
            }
          }
        )
        this.$router.push({name: 'SupplierPaymentConfirmation', params: {supplier_id: this.supplierId}})
      }
    },
    isNumber: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    maxPaymentSumValidation(maxPaymentSum) {
      return helpers.withParams(
        { type: "maxPaymentSum", maxPaymentSum },
        function (value) {
          return (
            parseFloat(value) &&
            parseFloat(maxPaymentSum) &&
            parseFloat(value) <= parseFloat(maxPaymentSum)
          );
        }
      );
    },
    setPaymentData() {
      let paymentData =
        this.current_payment_data &&
        this.supplierId in this.current_payment_data
          ? this.current_payment_data[this.supplierId]
          : null;

      if (paymentData) {
        this.contractNumber = paymentData.hasOwnProperty("contractNumber")
          ? paymentData.contractNumber
          : "";
        this.price = paymentData.hasOwnProperty("price")
          ? paymentData.price
          : "";
        this.phoneNumber = paymentData.hasOwnProperty("phoneNumber")
          ? paymentData.phoneNumber
          : "";
        this.sumToPay = paymentData.hasOwnProperty("sumToPay")
          ? paymentData.sumToPay
          : "";
      }
    },
  },
  updated: function () {},
};
</script>
<style lang="scss" scoped>
    .supplier-payment-186 {
        .supplier-logo {
            max-width: 100px;
        }
        .payment-product-name,
        .payment-form-section-bottom-block-text {
            font-size: 30px;
        }
    }
    .ltr-type {
        .payment-form {
           text-align: left; 
        }
        .input-tooltip-container {
            left: unset;
            right: 0;
        }
        .payment-form-with-tooltips .payment_field_wrapper .input {
            margin-left: 0;
            margin-right: 12px;
        }
    }
</style>