<template>
    <main class="supplier-payment supplier-payment-187 page_bg_grey">

        <section class="payment-caption-section">

            <div class="payment-caption-container">

                <div class="payment-caption-wrapper payment-product-name">
                    <span v-if="supplierData && supplierData.name">{{ supplierData.name }}</span>
                </div>

                <div class="payment-caption-wrapper text-center">
                    <img class="supplier-logo" v-if="supplierData && supplierData.image" :src="supplierData.image" />
                </div>

                <div class="payment-caption-wrapper">
                    &nbsp;
                </div>

            </div>

        </section>

        <section class="payment-form-section">

            <form class="payment-form payment-form-with-tooltips payment-187">

                <div class="payment_field_wrapper">
                    <label class="label payment_field_label" for="">
                        מספר ת.ז/ח.פ
                    </label>
                    <input id="customerId"
                           class="input"
                           type="tel"
                           v-model="customerId"
                           v-model.lazy="$v.customerId.$model"
                           :class="{ 'validation-error': $v.customerId.$error }">

                    <div class="input-tooltip-container">
                        <div class="input-tooltip-wrapper">
                            <div class="input-tooltip-icon">?</div>
                            <div class="input-tooltip-content">
                                <div><span class="yellow-text">מספר ת.ז. / ח.פ.</span></div>
                                <div>מספר זיהוי כפי שמופיע בצידה השמאלי העליון של החשבונית</div>
                                <div><img src="/assets/img/customer_id_field_description.png"/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="payment_field_wrapper">
                    <label class="label payment_field_label" for="">
                        6 ספרות אחרונות של מספר חשבונית
                    </label>
                    <input id="billNumber"
                           class="input"
                           type="tel"
                           v-model="billNumber"
                           v-model.lazy="$v.billNumber.$model"
                           :class="{ 'validation-error': $v.billNumber.$error }">

                    <div class="input-tooltip-container">
                        <div class="input-tooltip-wrapper">
                            <div class="input-tooltip-icon">?</div>
                            <div class="input-tooltip-content">
                                <div><span class="yellow-text">6 ספרות אחרונות של חשבונית</span></div>
                                <div>שש הספרות האחרונות (המסומנות בקו) של מספר החשבונית המופיע בצידה הימני העליון של החשבונית</div>
                                <div><img src="/assets/img/bill_number_field_description.png"/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="payment_field_wrapper">
                    <label class="label payment_field_label" for="">
                        סכום חשבונית
                    </label>
                    <input id="sumToPay"
                           class="input"
                           type="number"
                           step="0.01"
                           v-model="sumToPay"
                           v-model.lazy="$v.sumToPay.$model"
                           :class="{ 'validation-error': $v.sumToPay.$error }">
                </div>

                <div class="payment_field_wrapper">
                    <label class="label payment_field_label" for="">
                        סכום לתשלום
                    </label>
                    <input id="price"
                           class="input"
                           type="number"
                           step="0.01"
                           disabled="disabled"
                           v-model="price"
                           v-model.lazy="$v.price.$model"
                           :class="{ 'validation-error': $v.price.$error }">
                </div>

                <div class="payment_field_wrapper">
                    <label class="label payment_field_label" for="">
                        מספר טלפון נייד
                    </label>
                    <input id="phoneNumber"
                           class="input"
                           type="tel"
                           v-model="phoneNumber"
                           v-model.lazy="$v.phoneNumber.$model"
                           :class="{ 'validation-error': $v.phoneNumber.$error }">
                </div>

                <a class="link payment-button" @click="handleSupplierPaymentSubmit">שלם</a>

            </form>

            <div class="payment-form-section-bottom-block">
                <span class="payment-form-section-bottom-block-text">בסיום התשלום צריך לספק ללקוח קבלה בלבד!</span>
            </div>

        </section>

    </main>
</template>

<script>
    import {mapState, mapMutations} from 'vuex'
    import appConfig from '../../../appConfig'
    import { validationMixin } from 'vuelidate'
    import { required, minLength, maxLength, minValue } from 'vuelidate/lib/validators'
    import { helpers } from 'vuelidate/lib/validators'

    export default {
        mixins: [validationMixin],
        name: 'SupplierPayment187',
        props: {
            msg: String
        },
        data: () => {
            return {
                customerId: '',
                billNumber: '',
                price: '',
                phoneNumber: '',
                sumToPay: ''
            }
        },
        validations() {
            return {
                customerId: {
                    required,
                    customerIdValidation: this.customerIdValidation
                },
                billNumber: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(6)
                },
                sumToPay: {
                    required,
                    minValue: minValue(0)
                },
                price: {
                    required,
                    maxPaymentSum: this.maxPaymentSumValidation(this.maxPaymentSum)
                },
                phoneNumber: {
                    required,
                    minLength: minLength(10),
                    maxLength: maxLength(10)
                },
            }
        },
        mounted() {
        },
        computed: {
            ...mapState({
                supplier_items: state => state.supplier.supplier_items,
                suppliers: state => state.supplier.suppliers,
                suppliers_products: state => state.supplier.suppliers_products,
                current_payment_data: state => state.supplier_payment.current_payment_data
            }),
            supplierId() {
                return this.$route.params.supplier_id
            },
            supplierData() {
                let supplierDataFromSuppliersItemsData = null;
                if (this.supplier_items) {
                    for (let supplier_type in this.supplier_items) {
                        for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                            if (suppliers_type_item.id == this.supplierId) {
                                supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                                break;
                            }
                        }
                    }
                }

                let supplierDataFromSuppliersData = null;
                if (this.suppliers && this.supplierId in this.suppliers) {
                    supplierDataFromSuppliersData = {...this.suppliers[this.supplierId]}
                }

                let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
                return supplierData ? supplierData : null
            },
            supplierProductsAll() {
                let supplierProducts = []
                if (this.suppliers_products && this.supplierId in this.suppliers_products) {
                    let supplerProductsByType = this.suppliers_products[this.supplierId]

                    if (supplerProductsByType) {
                        for (let supplier_product_type in supplerProductsByType) {
                            for (let supplier_product of supplerProductsByType[supplier_product_type]) {
                                supplierProducts.push(supplier_product)
                            }
                        }
                    }
                }

                return supplierProducts ? supplierProducts : []
            },
            productId() {
                let productId = null

                if (this.productData && 'id' in this.productData && this.productData.id) {
                    productId = this.productData.id
                }

                return productId ? productId : null
            },
            productData() {
                let productData = null

                if (this.supplierProductsAll && this.supplierProductsAll.length
                    && '0' in this.supplierProductsAll && this.supplierProductsAll[0]) {
                    productData = this.supplierProductsAll[0]
                }

                return productData ? productData : null
            },
            finalCommission() {
                let finalCommission = 0

                if (this.productData && 'final_commission' in this.productData && this.productData.final_commission) {
                    finalCommission = this.productData.final_commission
                }

                return finalCommission ? finalCommission : 0
            },
            maxPaymentSum() {
                let maxPayment = 0

                if (this.productData && 'max_payment' in this.productData && this.productData.max_payment) {
                    maxPayment = this.productData.max_payment
                }

                return maxPayment ? maxPayment : 0
            }
        },
        watch: {
            sumToPay(sumToPay) {
                if (!this.isNumber(sumToPay) || !parseFloat(sumToPay)) {
                    sumToPay = ''
                }
                sumToPay = parseFloat(sumToPay)
                this.price = sumToPay + (this.finalCommission && this.isNumber(this.finalCommission)
                && this.finalCommission > 0 && parseFloat(this.finalCommission) ? parseFloat(this.finalCommission) : 0)
            }
        },
        created() {
            this.setPaymentData()
        },
        methods: {
            ...mapMutations('supplier_payment', {
                setCurrentPaymentData: 'setCurrentPaymentData'
            }),
            getApiURL() {
                return appConfig.apiUrl
            },
            handleSupplierPaymentSubmit() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }

                const supplierId = this.supplierId
                const itemId = this.productId
                const customerId = this.customerId
                const billNumber = this.billNumber
                const price = this.price
                const phoneNumber = this.phoneNumber
                const sumToPay = this.sumToPay
                const finalCommission = this.finalCommission

                if (supplierId != '' && itemId != '' && customerId != '' && billNumber != ''
                    && price != '' && phoneNumber != '' && sumToPay != ''
                    && finalCommission != '') {
                    this.setCurrentPaymentData(
                        {
                            supplier_id: supplierId,
                            payment_data: {
                                supplierId: supplierId,
                                itemId: itemId,
                                customerId: customerId,
                                billNumber: billNumber,
                                sumToPay: sumToPay,
                                price: price,
                                phoneNumber: phoneNumber,
                                finalCommission: finalCommission,
                            }
                        }
                    )
                    this.$router.push({name: 'SupplierPaymentConfirmation', params: {supplier_id: this.supplierId}})
                }
            },
            isNumber: function (n) {
                return !isNaN(parseFloat(n)) && isFinite(n);
            },
            customerIdValidation(value) {
                let customerIdStr = String(value);

                if ((customerIdStr.length > 9) || (customerIdStr.length < 5)) {
                    return false;
                }
                else if (isNaN(customerIdStr)) {
                    return false;
                }
                let counter = 0, incNum;
                for (var i = 0; i < 9; i++) {
                    incNum = Number(customerIdStr.charAt(i));
                    incNum *= (i % 2) + 1;
                    if (incNum > 9)
                        incNum -= 9;
                    counter += incNum;
                }
                if (counter % 10 === 0) {
                    return true;
                }
                return false;
            },
            maxPaymentSumValidation(maxPaymentSum) {
                return helpers.withParams(
                    {type: 'maxPaymentSum', maxPaymentSum},
                    function(value) {
                        return parseFloat(value) && parseFloat(maxPaymentSum) && parseFloat(value) <= parseFloat(maxPaymentSum)
                    }
                )
            },
            setPaymentData(){
                let paymentData = this.current_payment_data && (this.supplierId in this.current_payment_data) ? this.current_payment_data[this.supplierId] : null

                if (paymentData) {
                    this.customerId = paymentData.hasOwnProperty('customerId') ? paymentData.customerId : ''
                    this.billNumber = paymentData.hasOwnProperty('billNumber') ? paymentData.billNumber : ''
                    this.price = paymentData.hasOwnProperty('price') ? paymentData.price : ''
                    this.phoneNumber = paymentData.hasOwnProperty('phoneNumber') ? paymentData.phoneNumber : ''
                    this.sumToPay = paymentData.hasOwnProperty('sumToPay') ? paymentData.sumToPay : ''
                }
            }
        },
        updated: function () {
        },
    }
</script>

<style>
</style>